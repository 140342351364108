import { Container } from 'unstated';

export enum FeatureFlag {
	Analytics = 'ana',
	ListingBackButton = 'lbb',
	DigsAlert = 'dga',
	GoogleLoginV2 = 'gl2',
	Facebook = 'fbk',
	RecaptchaNoSignup = 'rns',
	TCsBug = 'tcb',
	ManageListingProductTour = 'mlt',
	UnhandledRejection = 'uhr',
	ManageListingEnhancements = 'mle',
	HomepageEnterToSearch = 'ets',
	SingleMobileNumberInput = 'sni',
	InvoiceDiscount = 'idc',
	ListingRoomCards = 'lrc',
	Inbox = 'ibx',
	InboxRedirect = 'ird',
	LandlordDashboard = 'lld',
	Recommendations = 'rmd',
	Simplify = 'spf',
	ClickToActivateSearchMap = 'ctam',
	AdButler = 'adb',
	HideInvoices = 'hic',
}

interface State {
	features: { [key: string]: boolean };
}

export const isStaging = (): boolean => window.location.host === 'staging.digsconnect.com';
export const isLocal = (): boolean => window.location.host === 'localhost:3000';
export const isProd = (): boolean =>
	window.location.host === 'digsconnect.com' || window.location.host === 'www.digsconnect.com';

// const isManuallyEnabled = (flag: FeatureFlag): boolean =>
//   window.location.search &&
//   window.location.search.includes(flag.toString()) || false;

class FeatureFlagContainer extends Container<State> {
	constructor() {
		super();

		this.state = {
			features: {
				[FeatureFlag.Analytics]: isProd() || isStaging(),
				[FeatureFlag.ListingBackButton]: !isProd(),
				[FeatureFlag.DigsAlert]: false,
				[FeatureFlag.GoogleLoginV2]: !isProd(),
				[FeatureFlag.Facebook]: false,
				[FeatureFlag.RecaptchaNoSignup]: true,
				[FeatureFlag.TCsBug]: true,
				[FeatureFlag.UnhandledRejection]: !isProd(),
				[FeatureFlag.ManageListingProductTour]: true,
				[FeatureFlag.ManageListingEnhancements]: true,
				[FeatureFlag.HomepageEnterToSearch]: true,
				[FeatureFlag.SingleMobileNumberInput]: true,
				[FeatureFlag.InvoiceDiscount]: true,
				[FeatureFlag.InboxRedirect]: true,
				[FeatureFlag.ListingRoomCards]: true,
				[FeatureFlag.LandlordDashboard]: false,
				[FeatureFlag.Recommendations]: false,
				[FeatureFlag.Inbox]: true,
				[FeatureFlag.Simplify]: true,
				[FeatureFlag.ClickToActivateSearchMap]: true,
				[FeatureFlag.AdButler]: !isProd(),
				[FeatureFlag.HideInvoices]: true,
			},
		};
	}

	isEnabled = (flag: FeatureFlag): boolean => {
		const { features } = this.state;
		return features[flag];
	};
}

export const featureFlagContainer = new FeatureFlagContainer();
